import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from "../components/seo"
import Hero from '../components/hero'
import Listing from '../components/listing'

import { prettyCategory } from '../utils/helpers'

/** @jsx jsx */
import { jsx } from 'theme-ui'

const singleCanton = (props) => {
    const listings = props.data.allPrismicEntreprise.edges.map( ({node: listing}) => listing )
    const category = props.pageContext.category
    const canton = props.pageContext.cantonName
    var resultsNumber = listings.filter( listing => listing !== null ).length

    // console.log("LISTINGS", listings)

    return (
        <Layout>
            <SEO title={""} description={""} />

            <Hero 
                resultsNumber={resultsNumber}
                category={category}
                canton={canton}
            />

            <div>            
                {listings.map( listing => (
                    <Listing 
                        key={listing.id}
                        uid={listing.uid}
                        data={listing.data}
                        prettyCategory={prettyCategory(category)}
                    />
                ))}
            </div>
        </Layout>
    )
}

export default singleCanton

export const singleCantonQuery = graphql`
    query singleCantonListings($category: String!, $canton: String!) {
        allPrismicEntreprise(
            filter: {data: {
                categories: {elemMatch: {category: {uid: {eq: $category}}}}, 
                locations: {elemMatch: {zones: {uid: {eq: $canton}}}},
                draft: {ne: true}
            }},
            sort: {fields: data___rank, order: ASC}
            ) {
            edges {
                node {
                    uid
                    ...ListingFragment
                }
            }
        }
    }
`